div.container.logboek table tbody tr td.el-table_1_column_2, div.container.opmerkingen table tbody tr td.el-table_1_column_3 {
  text-transform: capitalize;
}

input {
  padding: .375rem .25rem !important;
}

.el-table {
  z-index: 0;
}

.el-table__empty-text {
  display: none;
}

.ReactModal__Overlay textarea {
  width: 100%;
  min-height: 120px;
}

.navbar-nav .nav-link {
  font-weight: 500;
}

body {
  font-family: Roboto,Helvetica,Arial,sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  text-align: left;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.container {
  max-width: 960px;
}

.pricing-header {
  max-width: 700px;
}

.card-deck .card {
  min-width: 220px;
}

.border-top { border-top: 1px solid #e5e5e5; }
.border-bottom { border-bottom: 1px solid #e5e5e5; }

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }

.card-header+.list-group .list-group-item.list-group-item--header {
  background-color: #eee;
  border-top: 1px solid #bbb;
  font-weight: bold;
}

.card .card-header {
  font-size: 16px;
}

.card--availability .list-group .list-group-item:nth-child(odd) {
  background-color: #efefef;
}

.list-group-item :first-child {
  margin-right: 0;
}

.padleft{
  padding-left: 12px;
}

.selecmonth{
  padding-left: 10px;
  padding-top: 10px;
  font-size: 18px;
}

.logModal{
  transform: translateY(0%) !important;
}

.btn-A, .btn-A:hover, .btn-A:active, .btn-A:focus {
    background-color: #ffa726 !important;
    color: white !important;
}

.btn-B, .btn-B:hover, .btn-B:active, .btn-B:focus {
  background-color: #29b6f6 !important;
  color: white !important;
}

.btn-C, .btn-C:hover, .btn-C:active, .btn-C:focus {
  background-color: #66bb6a !important;
  color: white !important;
}

.btn-D, .btn-D:hover, .btn-D:active, .btn-D:focus {
  background-color: #ef5350 !important;
  color: white !important;
}

.userGroupButtons .dropdown-menu {
  padding: 0;
}

.nameGroupNonPlanMode {
  position: absolute;
  right: 0;
}

.ReactModal__Overlay {
  z-index: 1;
}

.list-group-item:focus, .list-group-item:hover {
  z-index: auto;
}

.grid {
  display: grid;
}
.grid--manage-neighbourhoods {
  grid-template-columns: minmax(100px, 300px) repeat(3, minmax(20%,25%));
}

.cursor-pointer {
  cursor: pointer;
}

.dropdown-menu-float {
  transform: translate3d(0px, -105px, 0px)!important;
}

.table-wrapper-scroll-y {
  display: block;
}

.table thead th {
  color: black !important;
}

.ReactModal__Overlay {
  top: 12% !important;
}

.webtitle {
  font-weight: bold !important;
  margin: auto;
}

.dropdown-menu {
  left: 75px;
}

.sticky {
  position: sticky;
  top: 0px;
}

.content {
  margin-top: 6em;
}

.navbar, .navbar-brand, .webtitle {
  font-size: 1rem !important;
}

td > button {
  z-index: 0;
}

#snackbars {
  z-index: 99999;
}

.bootbox .modal-header {
  flex-direction: row-reverse;
}
